import api from "../utils/axios-client";

const getStadiums = async () => {
  return api.get(`operator/stadiums`);
};
const postCreateStadium = async (stadiumInfo) => {
  // { nameAr, nameEn }
  return api.post(`operator/stadiums`, stadiumInfo);
};
const getStadiumById = async (recordId) => {
  return api.get(`operator/stadiums/${recordId}`);
};
const patchStadium = async (recordId, stadiumInfo) => {
  return api.patch(`operator/stadiums/${recordId}`, stadiumInfo);
};
const deleteStadium = async (recordId) => {
  return api.delete(`operator/stadiums/${recordId}`);
};

export const StadiumAPI = {
  getStadiums,
  postCreateStadium,
  getStadiumById,
  patchStadium,
  deleteStadium,
};
