import {
  ProForm,
  ProFormSelect,
  PageContainer,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { USER_ROLES } from "../../../utils/constant";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageUpload from "../../../components/UploadImage";
import { getUserData, patchUser } from "../../../api";
import { notification } from "antd";
import PermissionForm from "./PermissionForm";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userData, setUserData] = useState();
  const [userAvatar, setUserAvatar] = useState();

  const fetchUserData = useCallback(async () => {
    try {
      const response = await getUserData(id);

      setUserData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        ...values,
        avatar: userAvatar?.url ?? userData.avatar,
      };

      const response = await patchUser(id, newParams);

      if (response.status === 200) {
        navigate("/users");
        notification.success({
          message: "Update user successful! My friends",
        });
      }
    } catch (error) {
      notification.error({
        message: "Something went wrong! My friends",
      });
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <PageContainer title="Edit user">
      {userData && (
        <ProForm
          name="createUserForm"
          layout="horizontal"
          onFinish={handleSubmit}
          initialValues={userData}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="fullName"
                label="Full name"
                width={"lg"}
                placeholder="Enter full name"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        User name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="email"
                label="Email"
                width={"lg"}
                placeholder="Enter email"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Email is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="phoneNumber"
                label="Phone"
                width={"lg"}
                placeholder="Enter phone"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Phone is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              {/* <ProFormText
                name="address"
                label="Address"
                width={"lg"}
                placeholder="Enter address"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Address is required! My friends
                      </p>
                    ),
                  },
                ]}
              /> */}

              <ProFormText
                name="city"
                label="City"
                width={"lg"}
                placeholder="Enter city"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        City is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="nationality"
                label="Country"
                width={"lg"}
                placeholder="Enter country"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Country is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormSelect
                name="roles"
                width={"lg"}
                label="Role"
                placeholder="Enter role"
                options={USER_ROLES}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Role is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <PermissionForm
                userId={userData.id}
                permissionInfos={userData.userPrivileges}
                fetchUserData={fetchUserData}
              />

              <div id="switch-user" className="switch-button">
                <ProFormSwitch name="isVerify" label="Is verify?" />
              </div>
            </div>
          </ProForm.Group>

          <ProForm.Item label="Avatar:">
            <ImageUpload
              initialValue={userData?.avatar}
              callBackGetUrl={setUserAvatar}
            />
          </ProForm.Item>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditUser;
