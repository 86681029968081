import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageContainer,
  ProDescriptions,
  ProTable,
} from "@ant-design/pro-components";
import dayjs from "dayjs";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Image,
  Typography,
  notification,
} from "antd";
import {
  deleteQueue,
  getEventData,
  getQueue,
  getTicketTypesByEventId,
  postQueue,
  putQueue,
} from "../../../api";
import { ADD, EDIT, FOOD_COURT, REMOVE, SPORT } from "../../../utils/constant";
import parse from "html-react-parser";
import AddWaitingRoomModal from "../../../components/AddWaitingRoomModal";
import EditWaitingRoomModal from "../../../components/EditWailtingRoomModal";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import TicketTypeDraw from "../TicketTypeDetails/TicketTypeDraw";

const EventDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { Text } = Typography;

  const [eventData, setEventData] = useState();
  const [queueData, setQueueData] = useState();
  const [ticketTypes, setTicketTypes] = useState([]);
  const [isShowTicketDetailsDraw, setIsShowTicketDetailsDraw] = useState(false);
  const [selectedTicketType, setSelectedTicketType] = useState();
  const [isShowModal, setIsShowModal] = useState({
    deleteWaitingRoom: false,
    addWaitingRoom: false,
    putWaitingRoom: false,
  });
  const [isLoading, setIsLoading] = useState({
    post: false,
    put: false,
    delete: false,
  });

  const handleToggleWaitingRoomModal = (type) => {
    switch (type) {
      case ADD:
        setIsShowModal((prevState) => ({
          ...prevState,
          addWaitingRoom: !prevState.addWaitingRoom,
        }));
        break;

      case EDIT:
        setIsShowModal((prevState) => ({
          ...prevState,
          putWaitingRoom: !prevState.putWaitingRoom,
        }));
        break;

      default:
        break;
    }
  };

  const showTicketDraw = () => setIsShowTicketDetailsDraw(true);
  const cancelTicketDraw = () => setIsShowTicketDetailsDraw(false);

  const fetchEventData = useCallback(async () => {
    try {
      const response = await getEventData(id);

      setEventData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const fetchQueueData = useCallback(async () => {
    try {
      const response = await getQueue(id);

      setQueueData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const fetchTicketTypesByEventId = useCallback(async () => {
    try {
      const response = await getTicketTypesByEventId(id);

      setTicketTypes(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleRowOnClick = (record, rowIndex) => {
    showTicketDraw();
    setSelectedTicketType(record);
  };

  const handleSubmitWaitingRoom = async (type, values) => {
    try {
      switch (type) {
        case ADD:
          setIsLoading((prevState) => ({ ...prevState, post: true }));
          const queueParams = {
            countdownStartsOn: values.countdownStartsOn
              ? dayjs
                  .tz(
                    values.countdownStartsOn,
                    "YYYY-MM-DD HH:mm",
                    "Asia/Riyadh"
                  )
                  .utc()
                  .format()
              : null,
            queueActivatesOn: values.queueActivatesOn
              ? dayjs
                  .tz(
                    values.queueActivatesOn,
                    "YYYY-MM-DD HH:mm",
                    "Asia/Riyadh"
                  )
                  .utc()
                  .format()
              : null,
            capacity: values.capacity || null,
          };

          const postResponse = await postQueue(id, queueParams);

          if (postResponse.status === 201) {
            fetchEventData();
            notification.success({
              message: "Add permission successful",
            });
          }
          break;

        case EDIT:
          try {
            setIsLoading((prevState) => ({ ...prevState, put: true }));
            const queueParams = {
              ...queueData,
              message: values.message,
              countdownStartsOn: values.countdownStartsOn
                ? dayjs
                    .tz(
                      values.countdownStartsOn,
                      "YYYY-MM-DD HH:mm",
                      "Asia/Riyadh"
                    )
                    .utc()
                    .format()
                : null,
              queueActivatesOn: values.queueActivatesOn
                ? dayjs
                    .tz(
                      values.queueActivatesOn,
                      "YYYY-MM-DD HH:mm",
                      "Asia/Riyadh"
                    )
                    .utc()
                    .format()
                : null,
              capacity: values.capacity || null,
            };

            const response = await putQueue(eventData.id, queueParams);

            if (response.result && response.result.length > 1) {
              await fetchQueueData();
              notification.success({ message: "Update room successful" });
            }
          } catch (error) {
            console.log("Error", error);
            notification.error({ message: "Delete room failed" });
          }
          break;

        case REMOVE:
          try {
            setIsLoading((prevState) => ({ ...prevState, delete: true }));
            const response = await deleteQueue(eventData.id);
            if (response.status === 200) {
              notification.success({ message: "Delete room successful" });
              fetchEventData();
            }
          } catch (error) {
            console.log("Error", error);
            notification.error({ message: "Delete room failed" });
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading((prevState) => ({
        ...prevState,
        delete: false,
        put: false,
        post: false,
      }));
    }
  };

  const handleChangeEditRouter = () => navigate(`/events/${id}/edit`);

  useEffect(() => {
    fetchEventData();
  }, [fetchEventData]);

  useEffect(() => {
    fetchTicketTypesByEventId();
  }, [fetchTicketTypesByEventId]);

  useEffect(() => {
    if (eventData && eventData?.waitingRoomId) {
      fetchQueueData();
    }
  }, [eventData, fetchQueueData]);

  return (
    <>
      {eventData && (
        <PageContainer
          title={`Event details`}
          extra={[
            <Button
              key="edit-waiting-room"
              type="primary"
              onClick={() => handleChangeEditRouter()}
              loading={isLoading.put}
            >
              Edit event
            </Button>,
            <div>
              {!eventData?.waitingRoomId ? (
                <Button
                  key="add-waiting-room"
                  type="primary"
                  onClick={() => handleToggleWaitingRoomModal(ADD)}
                  loading={isLoading.post}
                >
                  Add waiting room
                </Button>
              ) : (
                <Button
                  key="edit-waiting-room"
                  type="primary"
                  onClick={() => handleToggleWaitingRoomModal(EDIT)}
                  loading={isLoading.put}
                >
                  Edit waiting room
                </Button>
              )}
            </div>,
            <Button
              key="event-permission"
              type="primary"
              onClick={() => navigate(`orders`)}
            >
              Orders
            </Button>,
            <Button
              key="sponsors"
              type="primary"
              onClick={() => navigate(`assign-sponsors`)}
            >
              Assign sponsors
            </Button>,
            <Button
              key="permission"
              type="primary"
              onClick={() => navigate("permission")}
            >
              Permission
            </Button>,
            <Button
              key="tickets"
              type="primary"
              onClick={() => navigate("tickets")}
            >
              Tickets
            </Button>,
            <Button
              key="histories"
              type="primary"
              onClick={() => navigate("check-in-histories")}
            >
              Check in histories
            </Button>,
          ]}
        >
          <Card title="Event Descriptions">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Event ID"
                valueType="text"
                span={1}
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {eventData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="English name" valueType="text">
                {eventData?.nameEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic name" valueType="text">
                {eventData?.nameAr}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card
            title="Waiting room"
            extra={
              eventData?.waitingRoomId && (
                <Button
                  key="remove-waiting-room"
                  type="primary"
                  onClick={() => handleSubmitWaitingRoom(REMOVE)}
                  loading={isLoading.delete}
                >
                  Delete
                </Button>
              )
            }
          >
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Room ID">
                {eventData?.waitingRoom?.roomId || "-"}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Room url">
                {eventData?.waitingRoom?.roomUrl || "-"}
              </ProDescriptions.Item>

              {eventData?.waitingRoom?.roomId && (
                <>
                  <ProDescriptions.Item label="Countdown start on">
                    {queueData?.countdownStartsOn
                      ? dayjs(queueData?.countdownStartsOn).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "-"}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Queue activate on">
                    {queueData?.queueActivatesOn
                      ? dayjs(queueData?.queueActivatesOn).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "-"}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Number of limit users">
                    {queueData?.capacity || "-"}
                  </ProDescriptions.Item>
                </>
              )}
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Event Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="English location" valueType="text">
                {eventData?.locationEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic location" valueType="text">
                {eventData?.locationAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="English city" valueType="text">
                {eventData?.cityEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic city" valueType="text">
                {eventData?.cityAr}
              </ProDescriptions.Item>

              {eventData?.category !== FOOD_COURT ? (
                <ProDescriptions.Item label="Stadium" valueType="text">
                  {eventData?.stadium?.nameAr}
                </ProDescriptions.Item>
              ) : (
                <>
                  <ProDescriptions.Item
                    label="Shipping Zone Id"
                    valueType="text"
                  >
                    {eventData?.eventZoneId}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item
                    label="Shipping Zone Name"
                    valueType="text"
                  >
                    {eventData?.zoneDetail?.name} |{" "}
                    {eventData?.zoneDetail?.name_en}
                  </ProDescriptions.Item>
                </>
              )}

              <ProDescriptions.Item label="Country" valueType="text">
                {eventData?.country}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Category" valueType="text">
                {eventData?.category}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Start time">
                {dayjs(eventData?.startTime).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Status" valueType="text">
                {eventData?.status}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Is featured" valueType="text">
                {eventData?.isFeatured ? "TRUE" : "FALSE"}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Final Statictics" valueType="text">
                Sold: {eventData?.finalData ? eventData?.finalData?.sold : 0}.
                Checked-in:{" "}
                {eventData?.finalData ? eventData?.finalData?.checkedIn : 0}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Category Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="English description"
                valueType="text"
              >
                <div>{parse(eventData?.metaData?.descriptionEn)}</div>
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic description" valueType="text">
                <div>{parse(eventData?.metaData?.descriptionAr)}</div>
              </ProDescriptions.Item>

              <ProDescriptions.Item label="English condition" valueType="text">
                <div>{parse(eventData?.metaData?.conditionEn)}</div>
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic condition" valueType="text">
                <div>{parse(eventData?.metaData?.conditionAr)}</div>
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          {eventData?.category === SPORT && (
            <>
              <Card title="Team Host">
                <ProDescriptions column={1}>
                  <ProDescriptions.Item label="English name" valueType="text">
                    {eventData?.metaData?.teamHost?.nameEn}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Arabic name" valueType="text">
                    {eventData?.metaData?.teamHost?.nameAr}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Score" valueType="text">
                    {eventData?.metaData?.hostScore}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Created at">
                    {dayjs(eventData?.metaData?.teamHost?.createdAt).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Club logo">
                    <Image
                      width={120}
                      src={eventData?.metaData?.teamHost?.logo}
                    />
                  </ProDescriptions.Item>
                </ProDescriptions>
              </Card>

              <Divider />

              <Card title="Team Guest">
                <ProDescriptions column={1}>
                  <ProDescriptions.Item label="English name" valueType="text">
                    {eventData?.metaData?.teamGuest?.nameEn}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Arabic name" valueType="text">
                    {eventData?.metaData?.teamGuest?.nameAr}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Score" valueType="text">
                    {eventData?.metaData?.guestScore}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Created at">
                    {dayjs(eventData?.metaData?.teamGuest?.createdAt).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                  </ProDescriptions.Item>

                  <ProDescriptions.Item label="Club logo">
                    <Image
                      width={120}
                      src={eventData?.metaData?.teamGuest?.logo}
                    />
                  </ProDescriptions.Item>
                </ProDescriptions>
              </Card>

              <Divider />
            </>
          )}

          <Card title="Event Images">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Created at">
                {dayjs(eventData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Title image">
                <Image width={300} src={eventData?.image} />
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Event detail image">
                <Image width={300} src={eventData?.eventDetailImage} />
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Ticket type image">
                <Image width={300} src={eventData?.ticketTypeImage} />
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card
            key="ticketTypes"
            title="Ticket Types"
            actions={[
              <Button
                key="submit"
                type="primary"
                onClick={() => navigate("ticket-types/new")}
              >
                Add ticket type
              </Button>,
            ]}
          >
            {ticketTypes && (
              <ProTable
                rowKey="id"
                columns={[
                  {
                    title: "English name",
                    dataIndex: "nameEn",
                  },
                  {
                    title: "Arabic name",
                    dataIndex: "nameAr",
                  },

                  {
                    title: "Arabic club name",
                    dataIndex: "club",
                    render: (_, record) => {
                      return (
                        <Text>
                          {record?.club?.logo && (
                            <Avatar src={record?.club?.logo} />
                          )}
                          {record?.club?.nameAr ?? "-"}
                        </Text>
                      );
                    },
                  },
                  {
                    title: "Ticket color",
                    dataIndex: "ticketColor",
                    render: (_, record) => {
                      return (
                        <div
                          style={{
                            backgroundColor:
                              record?.ticketColor?.backgroundColor,
                            height: 32,
                            width: 80,
                            borderRadius: 2,
                          }}
                        />
                      );
                    },
                  },
                  {
                    title: "Odoo id",
                    dataIndex: "ticketIdOdoo",
                  },
                  {
                    title: "Price",
                    dataIndex: "price",
                  },
                  {
                    title: "Gate",
                    dataIndex: "gate",
                  },
                  {
                    title: "Limit buying tickets",
                    dataIndex: "limitForUser",
                    width: 200,
                  },
                  {
                    title: "Remaining quantity",
                    dataIndex: "remainingQuantity",
                    width: 200,
                  },
                  {
                    title:
                      eventData?.category === FOOD_COURT
                        ? null
                        : "Stadium gate",
                    dataIndex:
                      eventData?.category === FOOD_COURT ? null : "gateId",
                    width: 200,
                    render: (_, record) => record?.gate?.nameAr,
                  },
                  {
                    key: "isBlock",
                    title: "Is Block",
                    dataIndex: "isBlock",
                    width: 100,
                    render: (_, record) => {
                      if (record?.isBlock) {
                        return (
                          <CheckCircleOutlined
                            style={{
                              color: "green",
                            }}
                          />
                        );
                      }

                      return (
                        <MinusCircleOutlined
                          style={{
                            color: "red",
                          }}
                        />
                      );
                    },
                  },
                  {
                    title: "Updated at",
                    dataIndex: "updatedAt",
                    render: (_, record) => (
                      <Typography>
                        {dayjs(record.updatedAt)
                          .tz("Asia/Kuwait")
                          .format("YYYY/MM/DD HH:mm")
                          .toString()}
                      </Typography>
                    ),
                  },
                ]}
                dataSource={ticketTypes}
                search={false}
                scroll={{ x: 1400 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => handleRowOnClick(record, rowIndex),
                  };
                }}
                // columnsState={{
                //   value: columnsStateMap,
                //   onChange: setColumnsStateMap,
                // }}
              />
            )}

            {/* <DuplicateCheckedInDraw
              open={isShowDuplicateCheckedInDraw}
              cancel={cancelDuplicateCheckedInDraw}
            /> */}

            {isShowModal.addWaitingRoom && (
              <AddWaitingRoomModal
                isOpen={isShowModal.addWaitingRoom}
                handleOk={handleSubmitWaitingRoom}
                handleCancel={handleToggleWaitingRoomModal}
              />
            )}

            {isShowModal.putWaitingRoom && (
              <EditWaitingRoomModal
                isOpen={isShowModal.putWaitingRoom}
                handleOk={handleSubmitWaitingRoom}
                handleCancel={handleToggleWaitingRoomModal}
                initialValues={queueData}
              />
            )}

            {isShowTicketDetailsDraw && (
              <TicketTypeDraw
                fetchTicketTypesByEventId={fetchTicketTypesByEventId}
                open={isShowTicketDetailsDraw}
                cancel={cancelTicketDraw}
                ticketData={selectedTicketType}
                ticketTypes={ticketTypes}
                setTicketTypes={setTicketTypes}
              />
            )}
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default EventDetails;
