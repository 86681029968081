import React, { useState } from "react";
import { ProForm } from "@ant-design/pro-components";
import CustomEditorToolbar from "../../components/RichTextEditorToolbar.js";

const FoodCourtType = ({ formData, eventData }) => {
  const [contentEditor, setContentEditor] = useState({
    descriptionEn: eventData?.metaData?.descriptionEn || "",
    descriptionAr: eventData?.metaData?.descriptionAr || "",
    conditionEn: eventData?.metaData?.conditionEn || "",
    conditionAr: eventData?.metaData?.conditionAr || "",
  });

  const handleChange = (value, key) => {
    const emptyString = "<p><br></p>";

    if (value === emptyString) {
      formData.setFieldValue(["metaData", key], null);
      setContentEditor((prevState) => ({ ...prevState, [key]: null }));
    } else {
      formData.setFieldValue(["metaData", key], value);
      setContentEditor((prevState) => ({ ...prevState, [key]: value }));
    }

    formData.validateFields([["metaData", key]]);
  };

  return (
    <>
      <ProForm.Item
        label="English Description"
        name={["metaData", "descriptionEn"]}
        className="w-full editor-field"
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                English description is required! My friends
              </p>
            ),
          },
        ]}
      >
        <CustomEditorToolbar
          handleChange={(value) => handleChange(value, "descriptionEn")}
          contentEditor={contentEditor.descriptionEn}
        />
      </ProForm.Item>

      <ProForm.Item
        label="Arabic Description"
        name={["metaData", "descriptionAr"]}
        className="w-full editor-field"
        style={{ height: 200 }}
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                Arabic description is required! My friends
              </p>
            ),
          },
        ]}
      >
        <CustomEditorToolbar
          handleChange={(value) => handleChange(value, "descriptionAr")}
          contentEditor={contentEditor.descriptionAr}
        />
      </ProForm.Item>

      <ProForm.Item
        label="English Condition"
        name={["metaData", "conditionEn"]}
        className="w-full editor-field"
        style={{ height: 200 }}
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                English conditions is required! My friends
              </p>
            ),
          },
        ]}
      >
        <CustomEditorToolbar
          handleChange={(value) => handleChange(value, "conditionEn")}
          contentEditor={contentEditor.conditionEn}
        />
      </ProForm.Item>

      <ProForm.Item
        label="Arabic Condition"
        name={["metaData", "conditionAr"]}
        className="w-full editor-field"
        style={{ height: 200 }}
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                Arabic conditions is required! My friends
              </p>
            ),
          },
        ]}
      >
        <CustomEditorToolbar
          handleChange={(value) => handleChange(value, "conditionAr")}
          contentEditor={contentEditor.conditionAr}
        />
      </ProForm.Item>
    </>
  );
};

export default FoodCourtType;
