import React, { memo, useCallback, useEffect, useState } from "react";
import {
  ProFormSwitch,
  ProFormText,
  ProFormGroup,
  DrawerForm,
  ProFormSelect,
} from "@ant-design/pro-components";
import { Button, Form, message } from "antd";
import { updateUserKeysPrivilegesAPI } from "../../../api";
import { StadiumAPI } from "../../../api/stadiums";
import { GatesAPI } from "../../../api/gates";
import { CustomizeOptionsByName } from "../../../utils/customizeOptions";

const UserPermissionsForm = ({ permissionInfos, userId, fetchUserData }) => {
  const initialStadium = permissionInfos.find(
    (item) => item.type === "CHECK_IN_WITH_GATE"
  )?.value?.stadium.id;
  const initialGate = permissionInfos.find(
    (item) => item.type === "CHECK_IN_WITH_GATE"
  )?.value?.id;

  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [stadiums, setStadiums] = useState([]);
  const [gates, setGates] = useState([]);

  const fetchStadiums = useCallback(async () => {
    try {
      const response = await StadiumAPI.getStadiums();

      setStadiums(CustomizeOptionsByName(response.data));
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const onGetGates = useCallback(async (stadiumId) => {
    try {
      const response = await GatesAPI.getGatesByStadiumId(stadiumId);

      setGates(CustomizeOptionsByName(response.data));
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  useEffect(() => {
    if (initialStadium) {
      onGetGates(initialStadium);
    }

    fetchStadiums();
  }, [fetchStadiums, initialStadium, onGetGates]);

  const onFinishForm = async (values) => {
    try {
      setLoading(true);
      const newPermissionInfos = permissionInfos.reduce((acc, cur) => {
        const isActive = values[cur.type];
        const privilege = {
          privilegeType: cur.type,
          active: isActive,
        };

        if (cur.key && isActive) {
          privilege[cur.key] = values[`${cur.type}-value`];
        }

        acc.push(privilege);

        return acc;
      }, []);
      await updateUserKeysPrivilegesAPI({
        userId,
        userPrivileges: newPermissionInfos,
      });

      await fetchUserData();
      message.success("ok");

      return true;
    } catch (error) {
      const {
        response: {
          data: { message: text },
        },
      } = error;

      message.error(text ?? error.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerForm
      title="User permissions"
      resize={{
        maxWidth: window.innerWidth * 0.8,
        minWidth: 500,
      }}
      layout="horizontal"
      form={form}
      trigger={<Button type="primary">Edit Permissions</Button>}
      autoFocusFirstInput
      drawerProps={{
        destroyOnClose: true,
      }}
      submitTimeout={2000}
      onFinish={onFinishForm}
      loading={isLoading}
    >
      {permissionInfos.map((permission, index) => (
        <ProFormGroup labelLayout="inline" key={index}>
          <ProFormSwitch
            name={permission.type}
            label={permission.type.replaceAll("_", " ")}
            initialValue={permission.active}
            layout="horizontal"
            onChange={(checked) => {
              form.setFieldsValue({ [permission.type]: checked });
            }}
          />

          {permission.key === "limit" && (
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[permission.type] !== currentValues[permission.type]
              }
            >
              {({ getFieldValue }) => {
                const isSwitchOn = getFieldValue(permission.type);
                return isSwitchOn ? (
                  <ProFormText
                    name={`${permission.type}-value`}
                    label={permission.key}
                    initialValue={permission.value ?? ""}
                    placeholder={`Enter value for ${permission.key}`}
                    rules={[
                      {
                        required: true,
                        message: () => (
                          <p className="error-message">
                            {permission.type.replaceAll("_", " ")} value
                          </p>
                        ),
                      },
                    ]}
                  />
                ) : null;
              }}
            </Form.Item>
          )}

          {permission.key === "gate" && (
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues[permission.type] !== currentValues[permission.type]
              }
            >
              {({ getFieldValue }) => {
                const isSwitchOn = getFieldValue(permission.type);
                return isSwitchOn ? (
                  <>
                    <ProFormSelect
                      name="stadium"
                      width={"lg"}
                      label="Stadium"
                      placeholder="Select stadium"
                      options={stadiums}
                      initialValue={initialStadium}
                      rules={[
                        {
                          required: true,
                          message: () => (
                            <p className="error-message">
                              Stadium users is required! My friends
                            </p>
                          ),
                        },
                      ]}
                      onChange={(value, options) => {
                        onGetGates(value);
                        form.setFieldValue(`${permission.type}-value`, null);
                      }}
                    />

                    <ProFormSelect
                      name={`${permission.type}-value`}
                      width={"lg"}
                      label="Gate"
                      placeholder="Select gate"
                      initialValue={initialGate}
                      rules={[
                        {
                          required: true,
                          message: () => (
                            <p className="error-message">
                              Gate is required! My friends
                            </p>
                          ),
                        },
                      ]}
                      options={gates}
                    />
                  </>
                ) : null;
              }}
            </Form.Item>
          )}
        </ProFormGroup>
      ))}
    </DrawerForm>
  );
};

export default memo(UserPermissionsForm);
