import { PlusOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Typography, Button, Modal } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import {
  DeleteFilled,
  FileTextFilled,
  EditFilled,
  ExclamationCircleFilled,
  UndoOutlined,
} from "@ant-design/icons";
import { SortingText, SortingDate } from "../../utils/sortingData";
import { deleteSubCategory, getSubCategories } from "../../api";
import { useForm } from "antd/lib/form/Form";
import { useLocation } from "react-router-dom";
import { parseQueryString } from "../../utils/parseQueryString";
import { createQueryStringUrl } from "../../utils/createQueryStringUrl";
import { EVENT_CATAGORIES } from "../../utils/constant";

const { Text } = Typography;

const SubCategories = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    category: queryString?.category || null,
    // startDate: queryString?.startDate || null,
    // endDate: queryString?.endDate || null,
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [filterSubCategories, setFilterSubCategories] =
    useState(customQueryString);

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsShowModal(true);
  };

  const handleCancel = () => {
    setIsShowModal(false);
  };

  const fetchSubCategories = useCallback(async () => {
    try {
      createQueryStringUrl(filterSubCategories);
      form.setFieldsValue(filterSubCategories);

      const response = await getSubCategories(
        filterSubCategories,
        currentPage,
        pageSize
      );

      setSubCategories(response?.results);
      setTotalItems(response?.total);
    } catch (err) {
      console.log("err");
    }
  }, [currentPage, filterSubCategories, form, pageSize]);

  const handleSubmitModal = async () => {
    try {
      const response = selectedRecord?.deleted
        ? console.log("Restored record")
        : await deleteSubCategory(selectedRecord?.id);

      if (response.status === 200) {
        await fetchSubCategories();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      handleCancel();
    }
  };

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterSubCategories({
      ...filterSubCategories,
      category: params?.category || formValues?.category || null,
      // startDate:
      //   params?.createdAt?.[0] && formValues?.createdAt?.[0]
      //     ? dayjs(params?.createdAt?.[0]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[0]).toISOString()
      //     : null,
      // endDate:
      //   params?.createdAt?.[1] && formValues?.createdAt?.[1]
      //     ? dayjs(params?.createdAt?.[1]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[1]).toISOString()
      //     : null,
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterSubCategories({
      category: null,
      // startDate: null,
      // endDate: null,
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchSubCategories();
  }, [fetchSubCategories]);

  const tableColumns = [
    {
      key: "nameEn",
      title: "English Name",
      dataIndex: "nameEn",
      search: false,
      sorter: (current, next) => SortingText(current.nameEn, next.nameEn),
    },
    {
      key: "nameAr",
      title: "Arabic Name",
      dataIndex: "nameAr",
      search: false,
      sorter: (current, next) => SortingText(current.nameAr, next.nameAr),
    },
    {
      key: "category",
      title: "Category",
      dataIndex: "category",
      valueEnum: EVENT_CATAGORIES,
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/sub-categories/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/sub-categories/${record?.id}/edit`)}
          />

          {record?.deleted ? (
            <Button
              className="icon-button"
              icon={<UndoOutlined />}
              onClick={() => showModal(record)}
            />
          ) : (
            <Button
              className="icon-button"
              icon={<DeleteFilled />}
              onClick={() => showModal(record)}
            />
          )}
        </div>,
      ],
    },
  ];

  return (
    <PageContainer title="All Sub Categories">
      <Modal
        open={isShowModal}
        onOk={() => handleSubmitModal()}
        onCancel={handleCancel}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          {selectedRecord?.deleted ? (
            <p>Are you sure to restore this record?</p>
          ) : (
            <p>Are you sure to delete this record?</p>
          )}
        </div>
      </Modal>

      <ProTable
        rowKey="id"
        request={({ pageSize, current }, sort, filter) => {
          setPageSize(pageSize);

          return {
            subCategories,
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       navigate(`${record?.id}`);
        //     },
        //   };
        // }}
        scroll={{ x: 1400, y: 500 }}
        columns={tableColumns}
        dataSource={subCategories}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/sub-categories/new");
            }}
            type="primary"
          >
            New Sub category
          </Button>,
        ]}
        search={{
          form: form,
          labelWidth: "auto",
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default SubCategories;
