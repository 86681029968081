import api from "../utils/axios-client";

const getGatesByStadiumId = (stadiumId) => {
  return api.get(`operator/stadiums/${stadiumId}/gates`);
};

const getGatesByEventId = (eventId) => {
  return api.get(`operator/events/${eventId}/gates`);
};

const postCreateGates = async (gatesInfo) => {
  // { nameAr, nameEn, stadiumId }
  return api.post(`operator/gates`, gatesInfo);
};

const patchEditGates = async (recordId, gateInfo) => {
  return api.patch(`operator/gates/${recordId}`, gateInfo);
};

const deleteGates = async (recordId) => {
  return api.delete(`operator/gates/${recordId}`);
};

export const GatesAPI = {
  getGatesByStadiumId,
  getGatesByEventId,
  postCreateGates,
  patchEditGates,
  deleteGates,
};
