import { LockOutlined, MobileOutlined } from "@ant-design/icons";
import {
  LoginForm,
  ProFormCaptcha,
  ProFormText,
  ProConfigProvider,
  ProFormSelect,
} from "@ant-design/pro-components";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getCountries } from "libphonenumber-js";
import { useForm } from "antd/lib/form/Form";
import { CustomizeOptionsByConstant } from "../../utils/customizeOptions";
import { getProfile, requestOTP, verifyOTP } from "../../api";

const getCountryCodes = CustomizeOptionsByConstant(getCountries());

const Login = () => {
  const navigate = useNavigate();
  const [form] = useForm();

  return (
    <ProConfigProvider hashed={false}>
      <div>
        <LoginForm
          initialValues={{ countryCode: "SA" }}
          form={form}
          onFinish={async ({ phoneNumber, captcha, countryCode }) => {
            try {
              const response = await verifyOTP(
                phoneNumber,
                captcha,
                countryCode
              );

              const profile = await getProfile(response.accessToken);
              if (profile?.roles !== "OPERATOR") {
                message.error("You don't have permission");
                return;
              }
              localStorage.setItem("token", response.accessToken);
              message.success("Login successfully, welcome aboard!");

              navigate("/");
            } catch (error) {
              console.log("error", error);
              message.error(error.error);
            }
          }}
          logo="logo.png"
          subTitle="Locate Booking Secret Base"
          submitter={{
            searchConfig: { submitText: "Login" },
          }}
          autoFocusFirstInput
        >
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 16,
              }}
            >
              <ProFormSelect
                name="countryCode"
                fieldProps={{
                  size: "large",
                  defaultValue: "SA",
                }}
                width={"70px"}
                rules={[
                  {
                    required: true,
                    message: "Country code is required! My friends",
                  },
                ]}
                placeholder="Select country code"
                options={getCountryCodes}
                showSearch
              />

              <ProFormText
                fieldProps={{
                  size: "large",
                  prefix: <MobileOutlined className={"prefixIcon"} />,
                  style: { flex: 1 },
                }}
                width={"100%"}
                name="phoneNumber"
                placeholder={"Phone number"}
                rules={[
                  {
                    required: true,
                    message: "Phone number is required!",
                  },
                ]}
              />
            </div>

            <ProFormCaptcha
              fieldProps={{
                size: "large",
                prefix: <LockOutlined className={"prefixIcon"} />,
              }}
              captchaProps={{
                size: "large",
              }}
              placeholder={"OTP"}
              captchaTextRender={(timing, count) => {
                if (timing) {
                  return `${count} ${"seconds"}`;
                }
                return "Get OTP";
              }}
              name="captcha"
              phoneName={["phoneNumber", "countryCode"]}
              rules={[
                {
                  // required: true,
                  message: "OTP is required!",
                },
              ]}
              onGetCaptcha={async () => {
                try {
                  const fieldValues = form.getFieldsValue();

                  const response = await requestOTP(fieldValues);

                  if (response) {
                    if (process.env.NODE_ENV === "development") {
                      message.success(`Your OTP is：${response}`);
                      return;
                    }

                    message.success(
                      "OTP had sent to your phone, please check!"
                    );
                  }
                } catch (error) {
                  console.log("Error OTP:", error);
                }
              }}
            />
          </>
        </LoginForm>
      </div>
    </ProConfigProvider>
  );
};
export default Login;
